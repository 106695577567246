/** @jsxImportSource react */
import { MapContextProvider } from '@react/ReactGoogleMap';
import useSearchBox from '@react/search/hooks/useSearchBox';
import { bool, func, object, shape, string } from 'proptypes';

import SearchBox from '../SearchBox';

const SearchBoxWrapper = ({
  address,
  onTextChange,
  onLocationSearch,
  onCurrentLocation,
  include = '',
  toc,
  eventProps = {},
  invalid,
  onBlur,
  includeAllTypes,
  types,
  disableGoogleSearch,
  ...props
}) => {
  const { componentProps } = useSearchBox({
    address,
    onCurrentLocation,
    onBlur,
    eventProps,
    toc,
    onLocationSearch,
    includeAllTypes,
    include,
    onTextChange,
    types,
    disableGoogleSearch,
  });
  return <SearchBox {...componentProps} invalid={invalid} {...props} />;
};

const SearchBoxContainer = ({ ...props }) => {
  return (
    <MapContextProvider>
      <SearchBoxWrapper {...props} />
    </MapContextProvider>
  );
};

SearchBoxContainer.propTypes = {
  address: string,
  locationInfo: shape({
    geo: shape({
      latitude: string.isRequired,
      longitude: string.isRequired,
    }),
  }),
  onTextChange: func,
  onLocationSearch: func,
  onCurrentLocation: func,
  include: string,
  toc: string,
  eventProps: object,
  showSearchIcon: bool,
  invalid: bool,
  onBlur: func,
  disableGoogleSearch: bool,
};

export default SearchBoxContainer;
