/** @jsxImportSource react */
import { MapContext } from '@react/ReactGoogleMap';
import { APIProvider, useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';
import { gMapsApiKey } from 'sly/config';

const NONE = 'NONE';
const OK = 'OK';

const MapContextProvider = ({ children }) => {
  const [sessionToken, setSessionToken] = useState(null);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);

  const places = useMapsLibrary('places');
  const geocode = useMapsLibrary('geocoding');
  const routes = useMapsLibrary('routes');
  const streetView = useMapsLibrary('streetView');

  useEffect(() => {
    if (places && geocode && routes) {
      setSessionToken(new places.AutocompleteSessionToken());
      setAutocompleteService(new places.AutocompleteService());
      setGeocoder(new geocode.Geocoder());
      setDirectionsService(new routes.DirectionsService());
      setDirectionsRenderer(
        new routes.DirectionsRenderer({
          suppressMarkers: true,
        })
      );
    }
  }, [places, geocode, routes]);

  const getGeocode = async (query) => {
    if (!geocoder) throw new Error('Geocoder not initialized');
    return new Promise((resolve, reject) => {
      geocoder.geocode(query, (results, status) => {
        if ([OK, NONE].includes(status)) {
          resolve(results || []);
        } else {
          reject(new Error(status));
        }
      });
    });
  };

  const getAddressFromGeo = async (input) => {
    if (!geocoder) throw new Error('Geocoder not initialized');
    return new Promise((resolve, reject) => {
      const latlngStr = input.split(',', 2);
      const latlng = {
        lat: parseFloat(latlngStr[0]),
        lng: parseFloat(latlngStr[1]),
      };
      geocoder.geocode({ location: latlng }, (results, status) => {
        if ([OK, NONE].includes(status)) {
          resolve(results || []);
        } else {
          reject(new Error(status));
        }
      });
    });
  };

  const getPlacePredictions = async (input) => {
    if (!autocompleteService)
      throw new Error('AutocompleteService not initialized');
    return new Promise((resolve, reject) => {
      const query = {
        componentRestrictions: {
          country: 'us',
        },
        types: ['(regions)'],
        input,
        sessionToken,
      };
      autocompleteService.getPlacePredictions(query, (results, status) => {
        if ([OK, NONE].includes(status)) {
          resolve(results || []);
        } else {
          reject(new Error(status));
        }
      });
    });
  };

  return (
    <MapContext.Provider
      value={{
        getPlacePredictions,
        getGeocode,
        getAddressFromGeo,
        sessionToken,
        directionsRenderer,
        directionsService,
        streetView,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

const MapProvider = ({ children }) => {
  return (
    <APIProvider apiKey={gMapsApiKey}>
      <MapContextProvider>{children}</MapContextProvider>
    </APIProvider>
  );
};

export default MapProvider;
