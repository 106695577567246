import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M9.45217 16.1375C7.59963 16.1375 6.03025 15.4944 4.74402 14.2081C3.45778 12.9219 2.81467 11.3525 2.81467 9.49999C2.81467 7.64745 3.45778 6.07807 4.74402 4.79184C6.03025 3.5056 7.59963 2.86249 9.45217 2.86249C11.3047 2.86249 12.8741 3.5056 14.1603 4.79184C15.4466 6.07807 16.0897 7.64745 16.0897 9.49999C16.0897 10.2413 15.977 10.934 15.7516 11.578C15.5263 12.222 15.2216 12.79 14.8375 13.2821L20.3897 18.8402C20.6009 19.0554 20.7065 19.3216 20.7065 19.6388C20.7065 19.9561 20.5989 20.2203 20.3837 20.4315C20.1725 20.6427 19.9072 20.7484 19.588 20.7484C19.2688 20.7484 19.0036 20.6427 18.7924 20.4315L13.2462 14.8853C12.7502 15.2694 12.1782 15.5741 11.5301 15.7994C10.8821 16.0248 10.1895 16.1375 9.45217 16.1375ZM9.45217 13.8625C10.6663 13.8625 11.6969 13.4389 12.544 12.5918C13.3911 11.7447 13.8147 10.7141 13.8147 9.49999C13.8147 8.28585 13.3911 7.25524 12.544 6.40814C11.6969 5.56104 10.6663 5.13749 9.45217 5.13749C8.23803 5.13749 7.20742 5.56104 6.36032 6.40814C5.51322 7.25524 5.08967 8.28585 5.08967 9.49999C5.08967 10.7141 5.51322 11.7447 6.36032 12.5918C7.20742 13.4389 8.23803 13.8625 9.45217 13.8625Z"/>
</svg>`;

const Search = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='search' svg={svg} {...props} />
  )
);

Search.displayName = 'SearchIcon';

export default Search;
